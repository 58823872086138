.dialogContainer {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;

    .dialogHeader {
        display: block;
    }

    .dialogBodyItem {
        width: 100%;
        margin: 10px 0 10px 0;
    }
}
